
import {
    Component,
    Vue
} from 'vue-property-decorator'
import {
    apiFinancePartnerReportLists
} from '@/api/finance'
import {
    RequestPaging
} from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import PartnerLists from './components/partner-lists.vue'
import LsDialog from '../../components/ls-dialog.vue'
@Component({
    components: {
        LsPagination,
        ExportData,
        LsDialog,
        PartnerLists
    }
})
export default class UserManagement extends Vue {
    /** S Data **/
    // 分页查询
    pager: RequestPaging = new RequestPaging()
    /** E Data **/

    /** S Methods **/
    apiFinancePartnerReportLists = apiFinancePartnerReportLists // 传递给导出组件的api

    // 查询按钮
    query() {
        this.pager.page = 1
        this.getUserList()
    }

    //获取用户列表数据
    getUserList() {
        this.pager.request({
            callback: apiFinancePartnerReportLists,
            params: {}
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getUserList()
    }
    /** E Life Cycle **/
}
